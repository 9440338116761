@font-face {
  src: local("SF Pro Medium"),url('./fonts/FontsFree-Net-SFProDisplay-Medium.ttf');
  font-family:'SF Pro Medium';
  font-style: normal;
}

@font-face {
  src: local("SF Pro Regular"),url('./fonts/FontsFree-Net-SFProDisplay-Regular-1.ttf');
  font-family:'SF Pro Regular';
  font-style: normal;
}

@font-face {
  src: local("SF Pro"),url('./fonts/SFProDisplayThin.ttf');
  font-family:'SF Pro';
  font-style: normal;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#linkStyle:visited:link:hover:active {
  color: '#8000FF';
  text-decoration: 'none';
}


